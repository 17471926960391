export const emojiList = {
  version: '1',
  imgpath: 'https://mbdp03.bdstatic.com/sdks/emoticon/img/',
  packages: [
    {
      id: '1',
      name: 'emoji',
      package_icon: 'package_01.png',
      emoticons: [
        {
          id: '001001',
          text: '[微笑]',
          icon: 'face_01.png'
        },
        {
          id: '001004',
          text: '[大笑]',
          icon: 'face_04.png'
        },
        {
          id: '001005',
          text: '[鼓掌]',
          icon: 'face_05.png'
        },
        {
          id: '001206',
          text: '[嘻嘻]',
          icon: 'face_206.png'
        },
        {
          id: '001023',
          text: '[色]',
          icon: 'face_23.png'
        },
        {
          id: '001025',
          text: '[得意]',
          icon: 'face_25.png'
        },
        {
          id: '001159',
          text: '[挤眼]',
          icon: 'face_159.png'
        },
        {
          id: '001071',
          text: '[滑稽]',
          icon: 'face_71.png'
        },
        {
          id: '001197',
          text: '[困]',
          icon: 'face_197.png'
        },
        {
          id: '001011',
          text: '[汗]',
          icon: 'face_11.png'
        },
        {
          id: '001073',
          text: '[左捂脸]',
          icon: 'face_73.png'
        },
        {
          id: '001072',
          text: '[捂脸]',
          icon: 'face_72.png'
        },
        {
          id: '001007',
          text: '[笑哭]',
          icon: 'face_07.png'
        },
        {
          id: '001014',
          text: '[发怒]',
          icon: 'face_14.png'
        },
        {
          id: '001021',
          text: '[害羞]',
          icon: 'face_21.png'
        },
        {
          id: '001194',
          text: '[黑线]',
          icon: 'face_194.png'
        },
        {
          id: '001196',
          text: '[可怜]',
          icon: 'face_196.png'
        },
        {
          id: '001024',
          text: '[舔屏]',
          icon: 'face_24.png'
        },
        {
          id: '001065',
          text: '[打call]',
          icon: 'face_65.png'
        },
        {
          id: '001003',
          text: '[期待]',
          icon: 'face_03.png'
        },
        {
          id: '001098',
          text: '[亲吻]',
          icon: 'face_98.png'
        },
        {
          id: '001012',
          text: '[抠鼻]',
          icon: 'face_12.png'
        },
        {
          id: '001219',
          text: '[ok啊]',
          icon: 'face_219.png'
        },
        {
          id: '001068',
          text: '[没眼看]',
          icon: 'face_68.png'
        },
        {
          id: '001034',
          text: '[偷笑]',
          icon: 'face_34.png'
        },
        {
          id: '001013',
          text: '[哼]',
          icon: 'face_13.png'
        },
        {
          id: '001033',
          text: '[你懂的]',
          icon: 'face_33.png'
        },
        {
          id: '001215',
          text: '[欧耶]',
          icon: 'face_215.png'
        },
        {
          id: '001016',
          text: '[不高兴]',
          icon: 'face_16.png'
        },
        {
          id: '001066',
          text: '[尬笑]',
          icon: 'face_66.png'
        },
        {
          id: '001239',
          text: '[泪奔]',
          icon: 'face_239.png'
        },
        {
          id: '001019',
          text: '[大哭]',
          icon: 'face_19.png'
        },
        {
          id: '001020',
          text: '[流泪]',
          icon: 'face_20.png'
        },
        {
          id: '001038',
          text: '[鄙视]',
          icon: 'face_38.png'
        },
        {
          id: '001015',
          text: '[委屈]',
          icon: 'face_15.png'
        },
        {
          id: '001228',
          text: '[挠头]',
          icon: 'face_228.png'
        },
        {
          id: '001192',
          text: '[费解]',
          icon: 'face_192.png'
        },
        {
          id: '001026',
          text: '[疑问]',
          icon: 'face_26.png'
        },
        {
          id: '001226',
          text: '[苦思冥想]',
          icon: 'face_226.png'
        },
        {
          id: '001027',
          text: '[晕]',
          icon: 'face_27.png'
        },
        {
          id: '001051',
          text: '[爱钱]',
          icon: 'face_51.png'
        },
        {
          id: '001069',
          text: '[嘿哈]',
          icon: 'face_69.png'
        },
        {
          id: '001029',
          text: '[二哈]',
          icon: 'face_29.png'
        },
        {
          id: '001028',
          text: '[大哈]',
          icon: 'face_28.png'
        },
        {
          id: '001030',
          text: '[三哈]',
          icon: 'face_30.png'
        },
        {
          id: '001217',
          text: '[惊恐]',
          icon: 'face_217.png'
        },
        {
          id: '001241',
          text: '[石化]',
          icon: 'face_241.png'
        },
        {
          id: '001041',
          text: '[衰]',
          icon: 'face_41.png'
        },
        {
          id: '001042',
          text: '[骷髅]',
          icon: 'face_42.png'
        },
        {
          id: '001018',
          text: '[惊哭]',
          icon: 'face_18.png'
        },
        {
          id: '001009',
          text: '[啊]',
          icon: 'face_09.png'
        },
        {
          id: '001166',
          text: '[生病]',
          icon: 'face_166.png'
        },
        {
          id: '001037',
          text: '[再见]',
          icon: 'face_37.png'
        },
        {
          id: '001036',
          text: '[哈欠]',
          icon: 'face_36.png'
        },
        {
          id: '001187',
          text: '[并不简单]',
          icon: 'face_187.png'
        },
        {
          id: '001218',
          text: '[社会]',
          icon: 'face_218.png'
        },
        {
          id: '001216',
          text: '[嫌弃]',
          icon: 'face_216.png'
        },
        {
          id: '001031',
          text: '[白眼]',
          icon: 'face_31.png'
        },
        {
          id: '001002',
          text: '[开心]',
          icon: 'face_02.png'
        },
        {
          id: '001067',
          text: '[坏笑]',
          icon: 'face_67.png'
        },
        {
          id: '001022',
          text: '[亲亲]',
          icon: 'face_22.png'
        },
        {
          id: '001221',
          text: '[撇嘴]',
          icon: 'face_221.png'
        },
        {
          id: '001188',
          text: '[馋嘴]',
          icon: 'face_188.png'
        },
        {
          id: '001203',
          text: '[思考]',
          icon: 'face_203.png'
        },
        {
          id: '001220',
          text: '[耶耶耶]',
          icon: 'face_220.png'
        },
        {
          id: '001191',
          text: '[顶]',
          icon: 'face_191.png'
        },
        {
          id: '001154',
          text: '[感冒]',
          icon: 'face_154.png'
        },
        {
          id: '001222',
          text: '[emmm]',
          icon: 'face_222.png'
        },
        {
          id: '001044',
          text: '[闭嘴]',
          icon: 'face_44.png'
        },
        {
          id: '001231',
          text: '[摸头]',
          icon: 'face_231.png'
        },
        {
          id: '001209',
          text: '[右哼哼]',
          icon: 'face_209.png'
        },
        {
          id: '001210',
          text: '[左哼哼]',
          icon: 'face_210.png'
        },
        {
          id: '001202',
          text: '[失望]',
          icon: 'face_202.png'
        },
        {
          id: '001017',
          text: '[囧]',
          icon: 'face_17.png'
        },
        {
          id: '001039',
          text: '[抓狂]',
          icon: 'face_39.png'
        },
        {
          id: '001040',
          text: '[咒骂]',
          icon: 'face_40.png'
        },
        {
          id: '001070',
          text: '[前面的别走]',
          icon: 'face_70.png'
        },
        {
          id: '001050',
          text: '[友军]',
          icon: 'face_50.png'
        },
        {
          id: '001049',
          text: '[同上]',
          icon: 'face_49.png'
        },
        {
          id: '001195',
          text: '[互粉]',
          icon: 'face_195.png'
        },
        {
          id: '001122',
          text: '[加油啊]',
          icon: 'face_122.png'
        },
        {
          id: '001238',
          text: '[呕]',
          icon: 'face_238.png'
        },
        {
          id: '001189',
          text: '[吃瓜]',
          icon: 'face_189.png'
        },
        {
          id: '001227',
          text: '[奥利给]',
          icon: 'face_227.png'
        },
        {
          id: '001043',
          text: '[嘘]',
          icon: 'face_43.png'
        },
        {
          id: '001224',
          text: '[拒绝]',
          icon: 'face_224.png'
        },
        {
          id: '001225',
          text: '[观望]',
          icon: 'face_225.png'
        },
        {
          id: '001232',
          text: '[nonono]',
          icon: 'face_232.png'
        },
        {
          id: '001237',
          text: '[戳脸]',
          icon: 'face_237.png'
        },
        {
          id: '001236',
          text: '[走你]',
          icon: 'face_236.png'
        },
        {
          id: '001230',
          text: '[无所谓]',
          icon: 'face_230.png'
        },
        {
          id: '001223',
          text: '[暗中观察]',
          icon: 'face_223.png'
        },
        {
          id: '001190',
          text: '[打脸]',
          icon: 'face_190.png'
        },
        {
          id: '001010',
          text: '[哟]',
          icon: 'face_10.png'
        },
        {
          id: '001233',
          text: '[握草]',
          icon: 'face_233.png'
        },
        {
          id: '001234',
          text: '[苦笑]',
          icon: 'face_234.png'
        },
        {
          id: '001032',
          text: '[阴险]',
          icon: 'face_32.png'
        },
        {
          id: '001045',
          text: '[呆]',
          icon: 'face_45.png'
        },
        {
          id: '001168',
          text: '[太开心]',
          icon: 'face_168.png'
        },
        {
          id: '001186',
          text: '[悲伤]',
          icon: 'face_186.png'
        },
        {
          id: '001148',
          text: '[爱你]',
          icon: 'face_148.png'
        },
        {
          id: '001006',
          text: '[悠闲]',
          icon: 'face_06.png'
        },
        {
          id: '001046',
          text: '[什么鬼]',
          icon: 'face_46.png'
        },
        {
          id: '001235',
          text: '[饮酒醉]',
          icon: 'face_235.png'
        },
        {
          id: '001047',
          text: '[吐]',
          icon: 'face_47.png'
        },
        {
          id: '001240',
          text: '[额]',
          icon: 'face_240.png'
        },
        {
          id: '001229',
          text: '[凝视]',
          icon: 'face_229.png'
        },
        {
          id: '001008',
          text: '[不要啊]',
          icon: 'face_08.png'
        },
        {
          id: '001175',
          text: '[武汉加油]',
          icon: 'face_175.png'
        },
        {
          id: '001256',
          text: '[温暖]',
          icon: 'face_256.png'
        },
        {
          id: '001257',
          text: '[Salute]',
          icon: 'face_257.png'
        },
        {
          id: '001258',
          text: '[叹气]',
          icon: 'face_258.png'
        },
        {
          id: '001259',
          text: '[裂开]',
          icon: 'face_259.png'
        },
        {
          id: '001260',
          text: '[厉害]',
          icon: 'face_260.png'
        },
        {
          id: '001261',
          text: '[吃鲸]',
          icon: 'face_261.png'
        },
        {
          id: '001262',
          text: '[比心]',
          icon: 'face_262.png'
        },
        {
          id: '001263',
          text: '[AWSL]',
          icon: 'face_263.png'
        },
        {
          id: '001264',
          text: '[打工人]',
          icon: 'face_264.png'
        },
        {
          id: '001265',
          text: '[小红花]',
          icon: 'face_265.png'
        },
        {
          id: '001266',
          text: '[金牌]',
          icon: 'face_266.png'
        },
        {
          id: '001267',
          text: '[牛啤]',
          icon: 'face_267.png'
        },
        {
          id: '001268',
          text: '[星星]',
          icon: 'face_268.png'
        },
        {
          id: '001269',
          text: '[半星]',
          icon: 'face_269.png'
        },
        {
          id: '001048',
          text: '[已阅]',
          icon: 'face_48.png'
        },
        {
          id: '001162',
          text: '[男孩儿]',
          icon: 'face_162.png'
        },
        {
          id: '001163',
          text: '[女孩儿]',
          icon: 'face_163.png'
        },
        {
          id: '001185',
          text: '[抱抱]',
          icon: 'face_185.png'
        },
        {
          id: '001193',
          text: '[跪了]',
          icon: 'face_193.png'
        },
        {
          id: '001204',
          text: '[摊手]',
          icon: 'face_204.png'
        },
        {
          id: '001167',
          text: '[酸]',
          icon: 'face_167.png'
        },
        {
          id: '001074',
          text: '[666]',
          icon: 'face_74.png'
        },
        {
          id: '001123',
          text: '[勤洗手]',
          icon: 'face_123.png'
        },
        {
          id: '001125',
          text: '[n95口罩]',
          icon: 'face_125.png'
        },
        {
          id: '001207',
          text: '[心]',
          icon: 'face_207.png'
        },
        {
          id: '001124',
          text: '[中国]',
          icon: 'face_124.png'
        },
        {
          id: '001062',
          text: '[蜡烛]',
          icon: 'face_62.png'
        },
        {
          id: '001055',
          text: '[鲜花]',
          icon: 'face_55.png'
        },
        {
          id: '001156',
          text: '[给力]',
          icon: 'face_156.png'
        },
        {
          id: '001160',
          text: '[礼物]',
          icon: 'face_160.png'
        },
        {
          id: '001057',
          text: '[庆祝]',
          icon: 'face_57.png'
        },
        {
          id: '001176',
          text: '[喜]',
          icon: 'face_176.png'
        },
        {
          id: '001150',
          text: '[旅行]',
          icon: 'face_150.png'
        },
        {
          id: '001173',
          text: '[围脖]',
          icon: 'face_173.png'
        },
        {
          id: '001165',
          text: '[伤心]',
          icon: 'face_165.png'
        },
        {
          id: '001060',
          text: '[赞同]',
          icon: 'face_60.png'
        },
        {
          id: '001097',
          text: '[祈福]',
          icon: 'face_97.png'
        },
        {
          id: '001095',
          text: '[双手鼓掌]',
          icon: 'face_95.png'
        },
        {
          id: '001211',
          text: '[作揖]',
          icon: 'face_211.png'
        },
        {
          id: '001200',
          text: '[拳头]',
          icon: 'face_200.png'
        },
        {
          id: '001212',
          text: '[haha]',
          icon: 'face_212.png'
        },
        {
          id: '001208',
          text: '[耶]',
          icon: 'face_208.png'
        },
        {
          id: '001205',
          text: '[握手]',
          icon: 'face_205.png'
        },
        {
          id: '001214',
          text: '[ok]',
          icon: 'face_214.png'
        },
        {
          id: '001198',
          text: '[来]',
          icon: 'face_198.png'
        },
        {
          id: '001201',
          text: '[弱]',
          icon: 'face_201.png'
        },
        {
          id: '001101',
          text: '[加油]',
          icon: 'face_101.png'
        },
        {
          id: '001213',
          text: '[NO]',
          icon: 'face_213.png'
        },
        {
          id: '001184',
          text: '[good]',
          icon: 'face_184.png'
        },
        {
          id: '001105',
          text: '[小黄鸭]',
          icon: 'face_105.png'
        },
        {
          id: '001102',
          text: '[泡泡枪]',
          icon: 'face_102.png'
        },
        {
          id: '001104',
          text: '[棒棒糖]',
          icon: 'face_104.png'
        },
        {
          id: '001114',
          text: '[小老鼠]',
          icon: 'face_114.png'
        },
        {
          id: '001199',
          text: '[喵喵]',
          icon: 'face_199.png'
        },
        {
          id: '001183',
          text: '[猪头]',
          icon: 'face_183.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001053',
          text: '[国宝]',
          icon: 'face_53.png'
        },
        {
          id: '001054',
          text: '[羊驼]',
          icon: 'face_54.png'
        },
        {
          id: '001170',
          text: '[兔子]',
          icon: 'face_170.png'
        },
        {
          id: '001174',
          text: '[围观]',
          icon: 'face_174.png'
        },
        {
          id: '001118',
          text: '[钱袋]',
          icon: 'face_118.png'
        },
        {
          id: '001079',
          text: '[财神]',
          icon: 'face_79.png'
        },
        {
          id: '001117',
          text: '[灯笼]',
          icon: 'face_117.png'
        },
        {
          id: '001151',
          text: '[点亮平安灯]',
          icon: 'face_151.png'
        },
        {
          id: '001115',
          text: '[2020]',
          icon: 'face_115.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001120',
          text: '[舞狮]',
          icon: 'face_120.png'
        },
        {
          id: '001078',
          text: '[鞭炮]',
          icon: 'face_78.png'
        },
        {
          id: '001058',
          text: '[生日蛋糕]',
          icon: 'face_58.png'
        },
        {
          id: '001149',
          text: '[奥特曼]',
          icon: 'face_149.png'
        },
        {
          id: '001061',
          text: '[药丸]',
          icon: 'face_61.png'
        },
        {
          id: '001063',
          text: '[鸡蛋]',
          icon: 'face_63.png'
        },
        {
          id: '001155',
          text: '[干杯]',
          icon: 'face_155.png'
        },
        {
          id: '001180',
          text: '[炸鸡腿]',
          icon: 'face_180.png'
        },
        {
          id: '001152',
          text: '[肥皂]',
          icon: 'face_152.png'
        },
        {
          id: '001171',
          text: '[威武]',
          icon: 'face_171.png'
        },
        {
          id: '001157',
          text: '[给你小心心]',
          icon: 'face_157.png'
        },
        {
          id: '001178',
          text: '[音乐]',
          icon: 'face_178.png'
        },
        {
          id: '001082',
          text: '[火车]',
          icon: 'face_82.png'
        },
        {
          id: '001083',
          text: '[飞机]',
          icon: 'face_83.png'
        },
        {
          id: '001158',
          text: '[话筒]',
          icon: 'face_158.png'
        },
        {
          id: '001182',
          text: '[钟]',
          icon: 'face_182.png'
        },
        {
          id: '001181',
          text: '[照相机]',
          icon: 'face_181.png'
        },
        {
          id: '001161',
          text: '[绿丝带]',
          icon: 'face_161.png'
        },
        {
          id: '001172',
          text: '[微风]',
          icon: 'face_172.png'
        },
        {
          id: '001169',
          text: '[太阳]',
          icon: 'face_169.png'
        },
        {
          id: '001126',
          text: '[月亮]',
          icon: 'face_126.png'
        },
        {
          id: '001153',
          text: '[浮云]',
          icon: 'face_153.png'
        },
        {
          id: '001177',
          text: '[下雨]',
          icon: 'face_177.png'
        },
        {
          id: '001164',
          text: '[沙尘暴]',
          icon: 'face_164.png'
        },
        {
          id: '001179',
          text: '[赞啊]',
          icon: 'face_179.png'
        },
        {
          id: '001247',
          text: '[雪花]',
          icon: 'face_247.png'
        },
        {
          id: '001248',
          text: '[2021]',
          icon: 'face_248.png'
        },
        {
          id: '001250',
          text: '[牛年大吉]',
          icon: 'face_250.png'
        },
        {
          id: '001251',
          text: '[牛福到]',
          icon: 'face_251.png'
        },
        {
          id: '001252',
          text: '[云红包]',
          icon: 'face_252.png'
        },
        {
          id: '001253',
          text: '[牛洋洋]',
          icon: 'face_253.png'
        },
        {
          id: '001254',
          text: '[牛斯拉]',
          icon: 'face_254.png'
        },
        {
          id: '001255',
          text: '[云喝酒]',
          icon: 'face_255.png'
        },
        {
          id: '001242',
          text: '[工人]',
          icon: 'face_242.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001243',
          text: '[农民]',
          icon: 'face_243.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001244',
          text: '[稻草人]',
          icon: 'face_244.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001245',
          text: '[锦旗]',
          icon: 'face_245.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001246',
          text: '[大红花]',
          icon: 'face_246.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001127',
          text: '[→_→]',
          icon: 'face_127.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001035',
          text: '[睡觉]',
          icon: 'face_35.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001056',
          text: '[中国加油]',
          icon: 'face_56.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001059',
          text: '[MicDrop]',
          icon: 'face_59.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001064',
          text: '[浪]',
          icon: 'face_64.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001075',
          text: '[2018]',
          icon: 'face_75.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001076',
          text: '[福]',
          icon: 'face_76.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001077',
          text: '[红包]',
          icon: 'face_77.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001080',
          text: '[饺子]',
          icon: 'face_80.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001081',
          text: '[车票]',
          icon: 'face_81.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001084',
          text: '[射门]',
          icon: 'face_84.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001085',
          text: '[红牌]',
          icon: 'face_85.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001086',
          text: '[黄牌]',
          icon: 'face_86.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001087',
          text: '[哨子]',
          icon: 'face_87.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001088',
          text: '[比分]',
          icon: 'face_88.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001089',
          text: '[啤酒]',
          icon: 'face_89.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001090',
          text: '[足球]',
          icon: 'face_90.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001091',
          text: '[大力神杯]',
          icon: 'face_91.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001092',
          text: '[锦鲤]',
          icon: 'face_92.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001096',
          text: '[火焰]',
          icon: 'face_96.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001099',
          text: '[天使]',
          icon: 'face_99.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001100',
          text: '[樱花]',
          icon: 'face_100.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001103',
          text: '[气球]',
          icon: 'face_103.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001106',
          text: '[粽子]',
          icon: 'face_106.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001107',
          text: '[70周年]',
          icon: 'face_107.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001108',
          text: '[国庆]',
          icon: 'face_108.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001109',
          text: '[国庆快乐]',
          icon: 'face_109.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001110',
          text: '[圣诞老人]',
          icon: 'face_110.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001111',
          text: '[圣诞树]',
          icon: 'face_111.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001112',
          text: '[圣诞袜]',
          icon: 'face_112.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001113',
          text: '[铃铛]',
          icon: 'face_113.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001119',
          text: '[鼠年大吉]',
          icon: 'face_119.png',
          hideInEmojiKeyboard: true
        },
        {
          id: '001121',
          text: '[戴口罩]',
          icon: 'face_121.png',
          hideInEmojiKeyboard: true
        }
      ]
    }
  ]
};

export const emojiMap = emojiList.packages[0].emoticons.reduce((pre, cur) => {
  pre[cur.text] = cur.icon;
  return pre;
}, {});



// 转换富文本中EmojiUrl
export const transformEmojiUrl = (str = '', type) => {
  if (type === 'toEmojiString') {
    return str.replace(/<img.*?class=['"](.*?)['"].*?style=['"]height:.*?30px;['"].*?\/>/g, (str, p1) => '[' + p1 + ']');
  }
  if (type === 'toImgTag') {
    for (let key in emojiMap) {
      let reg = new RegExp('\\[' + key.slice(1, key.length - 1) + '\\]', 'g');
      str = str.replace(
        reg,
        `<img class='${key.slice(1, key.length - 1)}' style='display:"inline-block";width:24px;margin:0;position:relative;top:4px;' src='${emojiList.imgpath + emojiMap[key]} '/>`
      );
    }
    return str;
  }
  return '';
};
